@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.cluster {
  background-color: $pt-intent-primary;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
              0 1px 1px rgba(0, 0, 0, 0.2);
  color: $white;
  cursor: zoom-in;
  display: grid;
  font-size: 14px;
  font-weight: 600;
  height: 38px;
  line-height: 1;
  place-content: center;
  width: 38px;
}
