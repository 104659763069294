.vg-glimpse-item__video,
.vg-glimpse-item__wrapper {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

.vg-glimpse-item__video--stretch video {
  object-fit: fill;
}
