@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-anpr-alert__map {
  background-color: $light-gray2;
  display: flex;
  flex: 1;
  min-height: 20rem;
  position: relative;
}

// Google maps button color scheme
.#{$bp-ns}-button.vg-sitemap__button {
  background: #fff;
  left: 8px;
  border-radius: 2px;
  bottom: 8px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  color: #565656;
  font-size: 18px;
  min-height: 32px;
  padding: 5px 17px;
  position: absolute;

  &:hover {
    background: #ebebeb;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    color: #000;
  }
}

.vg-sitemap__backdrop {
  background-color: transparent;
}

.vg-sitemap {
  align-items: flex-start;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 121px;
  user-select: none;

  img {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1),
                0 4px 8px rgba(16, 22, 26, 0.2),
                0 18px 46px 6px rgba(16, 22, 26, 0.2);
    max-width: 50%;
  }
}
