.dates {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.message {
  font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', Helvetica, sans-serif;
  min-height: 120px;
  width: 100%;
}
