@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-user-session-page {
  margin: 0 0 20px;
  padding: 0;
}

.vg-user-sessions {
  list-style-type: none;
  margin: 0;
  padding: 0;

  & > li {
    border-top: 1px solid $pt-divider-black;
    padding: 20px;
  }

  & > li:first-child {
    border-top: 0;
  }

  & > li:nth-child(even) {
    background: rgba($gray5, 0.1);
  }

  .#{$bp-ns}-divider {
    margin: 20px 0 0;
  }
}

.vg-user-sessions__client__path {
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;

  & > span:first-child {
    flex: 1;
  }

  & > span:last-child {
    align-self: flex-end;
  }
}

.vg-user-sessions__info {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .#{$bp-ns}-button {
    margin-left: 10px;
  }
}

.vg-user-sessions__info__name {
  display: block;
  flex: 1;
}

.vg-user-sessions__video-sessions {
  border: 1px solid $pt-divider-black;
  list-style-type: none;
  margin: 20px 0 0;
  padding: 0;

  li {
    align-items: center;
    border-top: 1px solid $pt-divider-black;
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  li:first-child {
    border-top-width: 0;
  }

  & > li:nth-child(odd) {
    background: rgba($gray5, 0.15);
  }
}

.vg-user-sessions__video-sessions__error,
.vg-user-sessions__video-sessions__loading {
  flex: 2;
}

.vg-user-sessions__video-sessions__site,
.vg-user-sessions__video-sessions__stream {
  flex: 1;
}

.vg-user-sessions__video-sessions__duration {
  flex: 2;
  text-align: right;
}

.vg-user-sessions__other-users {
  text-align: center;
}
