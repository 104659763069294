@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.counts {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, minmax(min-content, 1fr));
  grid-template-rows: minmax(min-content, 1fr);
}

.hitbox {
  color: $gray1;
  cursor: pointer;
  display: block;
  opacity: 0.3;
  padding: 2px;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 1s;

  &:hover {
    opacity: 1;
    transition: opacity 0.2s;
  }
}

.insights {
  display: grid;
  grid-template-columns: minmax(250px, 1fr) minmax(800px, 6fr);
  grid-template-rows: minmax(min-content, 1fr);
  height: calc(100vh - 50px);
}

.results {
  background-color: $pt-app-background-color;
  display: grid;
  gap: 20px;
  grid-template-columns: minmax(250px, 1fr);
  grid-template-rows: min-content minmax(min-content, 1fr);
  padding: 20px;
}

.streamsList {
  background-color: $white;
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  height: calc(100vh - 50px);
  overflow-y: scroll;
  padding: 5px 0;

  :global {
    .#{$bp-ns}-tree-node-caret,
    .#{$bp-ns}-tree-node-caret-none {
      min-width: 10px;
    }
  }
}

.visual {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  grid-template-rows: minmax(min-content, 1fr);
}

.wrapper {
  display: grid;
  grid-template-columns: minmax(100px, 1fr);
  grid-template-rows: minmax(100px, 1fr);
  position: relative;
}
