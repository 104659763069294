@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-oversight {
  padding-bottom: 0;

  .#{$bp-ns}-dialog-header {
    z-index: 21;
  }

  .#{$bp-ns}-dialog-body {
    max-height: calc(100vh - 100px);
    margin: 0;
    overflow-y: scroll;
    padding: 0 20px 20px;
  }

  .#{$bp-ns}-card {
    margin: 20px 0 0;
  }

  .#{$bp-ns}-html-table {
    width: 100%;

    .#{$bp-ns}-text-muted {
      color: $pt-text-color-muted;
      text-align: right;
    }
  }

}
