@import "Grid/Grid";
@import "Sidebar/Sidebar";

.vg-streams__container {
  display: grid;
  grid-template-columns: 250px minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  height: calc(100vh - 50px);
}

.vg-streams--hideSidebar {
  grid-template-columns: 0 minmax(0, 1fr);
}
