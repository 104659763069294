.vg-error-callout--debug {
  background-color: $white;
  border-radius: $pt-border-radius;
  margin: 1rem 0 0 -28px;
  overflow-x: auto;

  pre {
    color: black;
    background: none;
    text-shadow: 0 1px white;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 0.8rem;
    hyphens: none;
    line-height: 1.5;
    margin: 0;
    padding: 0.5rem;
    tab-size: 4;
    text-align: left;
    white-space: pre;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
  }

  pre::-moz-selection, pre ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
  }

  pre::selection, pre ::selection {
    text-shadow: none;
    background: #b3d4fc;
  }

  .comment,
  .prolog,
  .doctype,
  .cdata {
    color: slategray;
  }

  .punctuation {
    color: #999;
  }

  .namespace {
    opacity: .7;
  }

  .property,
  .tag,
  .boolean,
  .number,
  .constant,
  .symbol,
  .deleted {
    color: #905;
  }

  .selector,
  .attr-name,
  .string,
  .char,
  .builtin,
  .inserted {
    color: #690;
  }

  .operator,
  .entity,
  .url,
  .language-css .string,
  .style .string {
    color: #9a6e3a;
    background: hsla(0, 0%, 100%, .5);
  }

  .atrule,
  .attr-value,
  .keyword {
    color: #07a;
  }

  .function,
  .class-name {
    color: #DD4A68;
  }

  .regex,
  .important,
  .variable {
    color: #e90;
  }

  .important,
  .bold {
    font-weight: bold;
  }
  .italic {
    font-style: italic;
  }

  .entity {
    cursor: help;
  }

}
