@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-user-email,
[data-player] .vg-user-email {
  align-items: center;
  background-color: rgba($dark-gray3, .7);
  border-radius: 0.25em;
  color: $pt-dark-text-color;
  display: flex;
  justify-content: center;
  padding: 0.75em 1.25em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); 
}

.vg-user-email--fullscreen {
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}
