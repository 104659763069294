@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-clappr-player {
  .vg-stream__spinner-player {
    display: flex;
    height: 100%;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
  }

  .#{$bp-ns}-spinner {
    margin: auto;
  }

  .player-error-screen,
  .play-wrapper {
    opacity: 0.5;
    transition: opacity 0.4s;
  }

  [data-player] {
    font-family: $pt-font-family;
  }

  div.player-error-screen__content[data-error-screen] {
    color: $pt-dark-text-color-muted;
  }

  .play-wrapper svg,
  [data-error-screen] svg {
    fill: $pt-dark-icon-color;
  }
}

.vg-clappr-player--stretch video {
  object-fit: fill;
}
