.devTag {
  font-size: 1rem;
  margin-left: 1rem;
  padding: 0.25rem 0.5rem;
}

.logos {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  a {
    display: flex;
  }
}

.vgridLogo {
  height: 30px;
  margin-left: -5px;
}

.customerLogo {
  max-height: 85%;
  margin-left: 0.75rem;
}
