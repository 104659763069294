@import "GlimpseRow/GlimpseRow";
@import "NewGlimpse/NewGlimpse";

.vg-streams__glimpse__buttons {
  padding: 15px 15px 10px;
}

.vg-streams__glimpse__items {
  list-style-type: none;
  margin: 0;
  padding: 0 0 10px;
}
