.vg-security-devices__devices {
  list-style-type: none;
  margin: 0;
  padding: 0;

  & > li {
    border-radius: $pt-border-radius;
    margin: 0;
    padding: 20px;
    position: relative;
  }

  & > :nth-child(odd) {
    background: $light-gray5;
  }

  strong {
    display: block;
  }

  ul {
    border: 1px solid $light-gray3;
    border-radius: $pt-border-radius;
    list-style-type: none;
    margin: 10px 0 0;
    padding: 0;
  }

  ul li {
    background: $light-gray5;
    border-top: 1px solid $light-gray3;
    padding: 10px 20px;
  }

  ul li:first-child {
    border-top: 0 none;
  }

  ul li:nth-child(odd) {
    background: $light-gray4;
  }

  & > :nth-child(odd) ul {
    border-color: $light-gray2;
  }

  & > :nth-child(odd) ul li {
    background: $light-gray4;
    border-color: $light-gray2;
  }

  & > :nth-child(odd) ul li:nth-child(odd) {
    background: $light-gray3;
  }

  button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
