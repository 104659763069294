@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.disabled {
  text-align: center;
}

.search {
  width: 100%;
}

.wrapper {
  align-items: center;
  display: flex;

  :global {
    .#{$bp-ns}-input {
      text-align: center;
      -webkit-appearance: none;
    }

    .#{$bp-ns}-popover-target {
      width: 100%;
    }

    .#{$bp-ns}-popover .#{$bp-ns}-menu {
      max-height: 410px;
      min-width: 500px;
    }

    .#{$bp-ns}-menu-item {
      padding: 10px 15px;
    }

    .#{$bp-ns}-menu-item .#{$bp-ns}-icon {
      margin-right: 15px;
    }
  
    .#{$bp-ns}-menu-item .#{$bp-ns}-icon svg {
      height: 12px;
      margin-top: 2px;
      width: 12px;
    }
  }
}
