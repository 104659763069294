@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-anpr-export__plates {
  background-color: $white;
  border-radius: $pt-border-radius;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.075);
  padding: 5px;

  .#{$bp-ns}-tag {
    margin-right: 5px;
  }
}

@media (min-width: 1200px) {
  .vg-anpr-export__wrapper {
    display: flex;
  }

  .vg-anpr-export__wrapper > .vg-anpr-export__preview,
  .vg-anpr-export__wrapper > .vg-anpr-export__request,
  .vg-anpr-export__wrapper > .vg-anpr-export__results {
    flex: 1;
  }

  .vg-anpr-export__stepper {
    padding: 0 20px 0 0;
    width: 20%;
  }
}
