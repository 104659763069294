@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-notifications__list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
  }

  li:nth-child(odd) {
    background-color: rgba($gray5, 0.15);
  }

  li:hover {
    background-color: rgba($gray5, 0.3);
  }

  li > .#{$bp-ns}-icon {
    margin: 20px;
    margin-right: 0;
  }

  li .#{$bp-ns}-button-group {
    flex: 0 0 auto;
    padding: 10px;
    padding-left: 0;
  }

  .vg-notifications__none {
    display: block;
    padding: 20px;
    pointer-events: none;
    text-align: center;
  }
}

.vg-notifications__icon {
  align-items: center;
  align-self: center;
  border-radius: 24px;
  display: flex;
  height: 48px;
  margin: 20px;
  justify-content: center;
  width: 48px;
}

.vg-notifications__content {
  flex: 1;
  padding: 20px;
  padding-left: 0;
}

.vg-notifications__title {
  font-size: 1.1em;
  font-weight: bold;
  padding-bottom: 4px;
}
