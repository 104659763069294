.vg-video-item__button {
  color: $pt-dark-icon-color;
  cursor: pointer;
  display: grid;
  height: 20px;
  opacity: 0.5;
  place-items: center;
  transition: color 0.5s, opacity 0.5s;
  width: 20px;

  &:hover {
    opacity: 1;
  }

  .vg-video-item:hover & {
    color: $white;
  }

  .vg-grid-wrapper--fullscreen & {
    display: none;
  }
}
