@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-streams__glimpse__item {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 30px;
  padding: 0 15px;

  &:hover {
    background-color: rgba($gray5, 0.4);
  }

  .#{$bp-ns}-icon {
    flex: none;
    margin-right: 7px;
  }
}

.vg-streams__glimpse__label {
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  word-wrap: normal;
}

.vg-streams__glimpse__description {
  white-space: pre;
}
