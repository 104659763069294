@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

div.card {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  padding: 10px;

  table {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 32px minmax(0, 7fr) 32px;
    padding: 0
  }

  tfoot,
  thead {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
  }

  tbody {
    display: grid;
    gap: 1px;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: repeat(7, minmax(0, 1fr));
    padding: 1px 0;
  }

  tr {
    display: grid;
    gap: 1px;
    grid-template-columns: 24px repeat(24, minmax(0, 1fr)) 24px;
    grid-template-rows: minmax(0, 1fr);
  }

  th {
    color: $pt-text-color-muted;
    display: grid;
    font-weight: 500;
    place-content: center;
  }

  td {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
  }
}

.count {
  background: $white;
  border-radius: $pt-border-radius;
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  padding: 10px;
}

.levelOne {
  background-color: #ebedf0;
}

.levelTwo {
  background-color: #9be9a8;
}

.levelThree {
  background-color: #40c463;
}

.levelFour {
  background-color: #30a14e;
}

.levelFive {
  background-color: #216e39;
}
