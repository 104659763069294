@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-admin__list-page {

  .#{$bp-ns}-html-table thead th {
    padding-top: 0;
  }

  .vg-paginator {
    padding: 15px 0 45px;
  }

  .vg-paginator__button {
    display: flex;
    flex: 1;
  }

  .vg-paginator__button:first-child {
    justify-content: flex-end;
  }

  .#{$bp-ns}-tree-node-label a {
    color: $dark-gray1;
    display: block;
    line-height: 30px;
    &.#{$bp-ns}-text-muted {
      color: $pt-text-color-muted;
    }
  }

  .#{$bp-ns}-tree-node-label a:hover {
    text-decoration: none;
  }

  table.#{$bp-ns}-html-table th {
    vertical-align: middle;
  }

  span.#{$bp-ns}-popover-target {
    display: flex;
  }
  .search-wrapper {
    margin-bottom: 20px;
    display: flex;
  }
  .search-wrapper > :not(:first-child) {
    margin-left: 10px;
  }
}

.vg-admin__list-page__search {
  flex: 1;
}
