@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-login {
  background-color: $pt-app-background-color;
  padding: 0;
  width: 27.5rem;

  .#{$bp-ns}-callout {
    margin-bottom: 1rem;
  }
}

.vg-login--notice {
  max-width: 46rem;

  ul {
    padding-left: 1.5rem;
  }

  .vg-login__footer {
    flex-direction: row-reverse;
  }
}

.vg-login__header {
  border-bottom: 1px solid $pt-divider-black;
}

.vg-login__logo__container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.25rem 0 0.75rem;
  width: 100%;

  .vg-login__logo__vgrid {
    height: 3.25rem;
  }

  .vg-login__logo__sc {
    height: 2.25rem;
    margin-bottom: 0.25rem;
  }
}

.vg-login__body {
  background-color: $white;
  border-radius: 0 0 6px 6px;
  padding: 2rem;
  padding-top: 1.5rem;
}

.vg-login__title {
  font-size: 1.5rem;
  line-height: 1;
  margin: 0 0 0.8rem;
}

.vg-login__customer-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  max-height: 10rem;
  margin-bottom: 1rem;
}

.vg-login__token input {
  text-align: center;
}

.vg-login__buttons {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.vg-login__sso {
  text-align: center;
}
