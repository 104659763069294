@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-pagination {
  display: grid;
  grid-template-columns: minmax(0, 1fr) min-content minmax(0, 1fr);
  padding: 15px 0;

  .vg-pagination__buttons {
    display: flex;
    flex: 1;
    grid-column: 2/3;
    justify-content: center;

    ul {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
      padding-right: 15px;
    }

    li {
      display: block;
      padding-left: 15px;
    }

    .#{$bp-ns}-button-text {
      min-width: 20px;
      text-align: center;
    }

    .#{$bp-ns}-disabled {
      opacity: 0.5;
    }
  }

  .vg-pagination__page-size {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}
