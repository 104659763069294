@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-admin__edit-page {
  //

  .#{$bp-ns}-breadcrumbs {
    height: 40px;
    margin-bottom: 15px;
  }

  .#{$bp-ns}-callout {
    margin-bottom: 20px;
  }
}

.vg-admin__form {
  //

  & > :nth-child(odd),
  & .#{$bp-ns}-collapse-body > :nth-child(even) {
    background: rgba($gray5, 0.15);
    border-radius: $pt-border-radius;
  }

  .#{$bp-ns}-form-group {
    align-items: stretch;
    flex-direction: row;
    margin: 0;
  }

  .#{$bp-ns}-label {
    flex: 1;
    max-width: 250px;
    padding-top: 6px;
    padding: 16px;
  }

  .#{$bp-ns}-form-content {
    margin: auto 0;
    flex: 3;
    padding: 10px;
    padding-left: 0;

    .#{$bp-ns}-control {
      margin: 0;
    }
  }

  .#{$bp-ns}-switch {
    margin: 0;
    padding: 4.845px 0;
  }
}

.vg-admin__form--legacy {

  table.#{$bp-ns}-html-table tr {
    border-radius: $pt-border-radius;
  }

  table.#{$bp-ns}-html-table td {
    padding: 0 11px 0 0;
  }

  table.#{$bp-ns}-html-table tbody tr:first-child td {
    box-shadow: none;
  }

  & > table:nth-child(odd) {
    background: transparent;
  }

}

.vg-admin__edit-page__relations {
  margin: 0 0 20px;
  padding: 9px 20px 20px;

  table.#{$bp-ns}-html-table td,
  table.#{$bp-ns}-html-table th {
    display: flex;
    padding: 0;
  }

  table.#{$bp-ns}-html-table .vg-admin__edit-page__relations__more td {
    display: block;
    padding: 11px;
    text-align: center;
  }

  thead .#{$bp-ns}-button {
    margin: 6px 0;
    min-height: 28px;
  }

  tbody .#{$bp-ns}-button {
    margin: 6px;
    min-height: 28px;
  }

  .vg-admin__edit-page__relations__page {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    * {
      margin: 0 6px;
    }

    span {
      line-height: 30px;
    }
  }
}

.vg-admin__edit-page__relations__name {
  flex: 1;
  padding: 11px;

  a {
    color: $pt-text-color
  }

  .#{$bp-ns}-text-disabled & a {
    color: rgba(95, 107, 124, 0.6);
  }   
}

.vg-admin__edit-page__relations__add-dialog {
  max-width: 500px;

  .#{$bp-ns}-alert-contents {
    display: flex;
    flex: 1;
  }
}

@media (min-width: 1200px) {
  .vg-admin__form {
    .vg-form--inline-content-wrapper {
      display: flex;
    }
  
    .vg-form--inline-content-wrapper > * {
      flex: 1;
    }
  
    .vg-form--inline-content-wrapper .#{$bp-ns}-form-content {
      flex: 1;
    }
  }
}