@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

html {
  background-color: $pt-app-background-color;
}

body {
  margin: 0;
  overscroll-behavior: none;
}

:-webkit-full-screen {
  background-color: $black;
  width: 100%;
}

:-moz-full-screen {
  background-color: $black;
  width: 100%;
}

:-ms-fullscreen {
  background-color: $black;
  width: 100%;
}

:fullscreen {
  background-color: $black;
  width: 100%;
}

#root {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 50px minmax(0, 1fr);
  min-height: 100vh;
}

.vg-page--loading .#{$bp-ns}-card {
  transition: none;
}

.vg-anonymous {
  background-color: $pt-dark-app-background-color;
  display: grid;
  min-height: 100vh;
  place-items: center;

  .vg-page--loading {
    color: $pt-dark-text-color;
    padding-top: 0;
  }

  .vg-page--loading .#{$bp-ns}-card {
    background-color: transparent;
    box-shadow: none;
  }
}

.vg-anonymous .vg-page__header {
  display: none;
}

.vg-anonymous > .#{$bp-ns}-overlay > .#{$bp-ns}-overlay-backdrop {
  background-color: transparent;
}

.vg-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1320px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.vg-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px;

  .#{$bp-ns}-html-table {
    width: 100%;
  }

  .#{$bp-ns}-html-table td {
    vertical-align: middle;
  }

  label.#{$bp-ns}-label {
    margin-bottom: 0;
  }
}

.vg-page__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 55px;
  padding-bottom: 15px;

  .#{$bp-ns}-breadcrumbs {
    flex: 1;
    height: 40px;
  }
}

.vg-page__header__buttons {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;

  .#{$bp-ns}-button {
    margin-left: 15px;
  }
}

.vg-page__footer {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 15px 0;
}

.vg-page__actions {
  display: flex;
  justify-content: flex-start;

  .#{$bp-ns}-button {
    margin-right: 15px;
  }

  .#{$bp-ns}-button.#{$bp-ns}-minimal {
    margin-right: 5px;
  }
}

.vg-page__buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  .#{$bp-ns}-button {
    margin-left: 15px;
  }

  .#{$bp-ns}-button.#{$bp-ns}-minimal {
    margin-left: 5px;
  }
}

.vg-page__button-spin-wrapper {
  display: flex;

  button {
    margin-left: 15px;
  }
}

.vg-spinner {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.vg-inactive {
  opacity: 0.5;
}

.vg-error-callout {
  margin: 0 auto;
  max-width: 64rem;
}

.#{$bp-ns}-card > .#{$bp-ns}-html-table:first-child thead th {
  padding-top: 0;
}

.#{$bp-ns}-suggest-popover {
  
  .#{$bp-ns}-popover-content {
    padding: 5px;
    
    .#{$bp-ns}-menu {
      max-height: 300px;
      overflow: auto;
      padding: 0;
    }
  }
}
