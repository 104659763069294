@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

$big-spacing: 8px;
$small-spacing: 4px;
$half-small-spacing: $small-spacing - 2;

@mixin top-bottom {
  right: 0;
  left: 0;
}

@mixin right-left {
  top: 0;
  bottom: 0;
}

.vg-video-ptz {
  position: absolute;
  background: rgba(0, 0, 0, .5);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 2;

  &.layout-big {
    width: 90px;
    height: 90px;
    left: $big-spacing;
    bottom: $big-spacing;
    padding: .5rem;

    .icon {
      &.zoom-out, &.zoom-in {
        bottom: -($big-spacing + 5);
      }

      &.top {
        top: 0;
      }
  
      &.right {
        right: 0;
      }
  
      &.bottom {
        bottom: 0;
      }
  
      &.left {
        left: 0;
      }

      &.top-right {
        right: $big-spacing;
        top: $big-spacing;
      }
  
      &.bottom-right {
        right: $big-spacing;
        bottom: $big-spacing;
      }
  
      &.bottom-left {
        left: $big-spacing;
        bottom: $big-spacing;
      }
  
      &.top-left {
        left: $big-spacing;
        top: $big-spacing;
      }
    }
  }

  &.layout-small {
    width: 70px;
    height: 70px;
    left: $small-spacing;
    bottom: $small-spacing;
    padding: .3rem;

    .icon {
      &.zoom-out, &.zoom-in {
        bottom: -10px;
      }

      &.top {
        top: -$half-small-spacing;
      }
  
      &.right {
        right: -$half-small-spacing;
      }
  
      &.bottom {
        bottom: -$half-small-spacing;
      }
  
      &.left {
        left: -$half-small-spacing;
      }

      &.top-right {
        right: $small-spacing;
        top: $small-spacing;
      }
  
      &.bottom-right {
        right: $small-spacing;
        bottom: $small-spacing;
      }
  
      &.bottom-left {
        left: $small-spacing;
        bottom: $small-spacing;
      }
  
      &.top-left {
        left: $small-spacing;
        top: $small-spacing;
      }
    }
  }

  .circle {
    position: relative;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    border: 1px solid $pt-text-color-muted;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error {
    pointer-events: all;
  }

  .icon {
    position: absolute;
    margin: auto;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.zoom-out {
      left: 0;
    }

    &.zoom-in {
      right: 0;
    }

    &.top, &.bottom {
      @include top-bottom;
    }

    &.right, &.left {
      @include right-left;
    }

    .vg-video-item__button {
      .vg-grid-wrapper--fullscreen & {
        display: block;
      }
    }
  }
}

.vg-video-ptz__canvas_div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}