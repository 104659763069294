@import "ControlBar/ControlBar";
@import "GlimpseItem/GlimpseItem";
@import "GridItem/GridItem";
@import "StreamItem/StreamItem";
@import "VideoItem/VideoItem";

.vg-grid__container {
  background-color: #000; // grid lines
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) 50px;
}

.vg-grid-wrapper {
  display: grid;
  place-items: stretch;
  position: relative;
}

.vg-grid {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  overflow: hidden;
  padding: 1px;

  .vg-grid-wrapper--fullscreen & {
    height: auto;
  }
}

.vg-grid--map {
  background-color: $gray4;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.vg-grid--mapMontage {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(0, 1fr));

  & > .vg-streams__map {
    grid-column: 2 / 4;
    grid-row: 2 / 4;
  }
}

.vg-grid--bigSmall {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));

  & > :first-child {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
}

.vg-grid--single {
  background-color: $gray4;
}

.vg-grid--twoTwo {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.vg-grid--threeTwo {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.vg-grid--threeThree {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.vg-grid--fourThree {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.vg-grid--fourFour {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(0, 1fr));
}
