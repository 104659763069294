@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-anpr-alert {
  .vg-page__header {
    min-height: 30px;
    padding-bottom: 0;
  }
}

.vg-anpr-alert__header {
  border: 1px solid $pt-divider-black;
  border-width: 1px 0 0;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  height: 350px;
  width: 100vw;
}

.vg-anpr-alert__info-bar {
  background-color: rgba($gray5, 0.15);
  border: 1px solid $pt-divider-black;
  border-width: 1px 0;
  padding: 10px 0;
}

.vg-anpr-alert__info-bar__wrapper {
  align-items: center;
  display: flex;
  margin: 0 auto;
}

.vg-anpr-alert__info-bar__time {
  flex: 1;
  padding-left: 20px;
}

.vg-anpr-alert__status-button--active {
  cursor: default;
  pointer-events: none;
}

.vg-anpr-alert__details {
  border-bottom: 1px solid $pt-divider-black;
  display: flex;
  flex-direction: row;
}

.vg-anpr-alert__detail-column {
  flex: 1;
}

.vg-anpr-alert__detail-column:first-child {
  padding-right: 10px;
}

.vg-anpr-alert__detail-column:last-child {
  padding-left: 10px;
}

.vg-anpr-alert__other-alerts {
  display: flex;
  padding: 20px 0;

  & > span {
    flex: 1;
    line-height: 30px;
    padding-right: 20px;
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .vg-anpr-alert__card {
    display: flex;
  }

  .vg-anpr-alert__details {
    border-bottom-width: 0;
    display: block;
    padding: 0 20px 0 0;
    width: 38.2%;
  }

  .vg-anpr-alert__detail-column:first-child,
  .vg-anpr-alert__detail-column:last-child {
    padding: 0;
  }

  .vg-anpr-alert__details .#{$bp-ns}-html-table:last-child {
    margin: 0;
  }

  .vg-anpr-alert__others-and-comments {
    flex: 1;
  }

  .vg-anpr-alert__other-alerts {
    padding-top: 0;
  }
}

@import "./Image/Image";
@import "./Map/Map";
