@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.accountEmail {
  font-size: 0.8rem;
}

.accountName {
  font-weight: bold;
}

.button {
  align-items: center;
  border-radius: $pt-border-radius;
  color: $pt-text-color;
  display: inline-flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: center;
  min-height: 30px;
  min-width: 30px;
  padding: 5px 10px;
  text-align: left;
  text-decoration: none;
  vertical-align: middle;

  .icon {
    color: $pt-icon-color;
    flex: 0 0 auto;
  }
}

.hitbox {
  cursor: pointer;
  padding: 10px 1px;
  user-select: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 5px;
    background: transparent;
  }

  .button.focus,
  &:focus .button,
  &:hover .button {
    background-color: rgba($gray4, 0.3);
    color: $pt-text-color;
    text-decoration: none;
  }
}

.button.active,
.button.focus.active,
.hitbox:focus .button.active,
.hitbox:hover .button.active {
  background-color: $pt-intent-primary;
  color: $white;
}

.button.active .icon,
.button.focus.active .icon,
.hitbox:focus .button.active .icon,
.hitbox:hover .button.active .icon {
  color: $white;
}

.navigation {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.text {
  flex: 0 1 auto;
}

.icon + .text {
  margin-left: 7px;
}

.release {
  strong {
    display: block;
  }

  span {
    display: block;
    font-size: 0.8rem;
  }

  time {
    display: block;
    font-size: 0.8rem;
    opacity: 0.5;
  }
}

.badge {
  align-items: center;
  background-color: $pt-intent-danger;
  border-radius: $pt-border-radius * 2;
  color: $white;
  display: flex;
  font-size: $pt-font-size-small;
  height: $pt-icon-size-large;
  justify-content: center;
  width: $pt-icon-size-large;
  position: absolute;
  right: -3px;
  top: 3px;
}

@media screen and (min-width: 1200px) {
  .help .icon {
    display: none;
  }

  .help .icon + .text {
    margin-left: 0;
  }
}

@media screen and (max-width: 1200px) {
  .help .text {
    display: none;
  }
}
