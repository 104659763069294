@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-notification__list {
  align-items: stretch;
  left: auto;
  max-width: 460px;
  min-width: 320px;
  padding: 1px 11px 30px 20px;
  position: fixed;
  top: 50px;
  z-index: 19;

  .#{$bp-ns}-toast {
    cursor: pointer;
    max-width: none;
    min-width: auto;
    width: 100%;
  }
}

.vg-notification__title {
  font-weight: bold;
  padding-bottom: 2px;
}

.vg-notification__message {
  font-size: 0.93em;
  opacity: 0.8;
}

.vg-notification__buttons {
  display: flex;

  .#{$bp-ns}-toast {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    opacity: 0.45;
    padding: 11px;
    text-align: center;
    transition: opacity 0.5s;

    span {
      font-size: 0.93em;
      opacity: 0.8;
    }

    &:hover {
      opacity: 0.9;
      transition: opacity 0.1s;
    }
  }

  .vg-notification__clear {
    background-color: $red3;
    color: $white;
    flex: 1;
  }

  .vg-notification__more {
    flex: 3;
    margin-right: 10px;
  }
}

.vg-anonymous {
  .vg-notification__list {
    top: 10px;
    z-index: 25;
  }
}
