@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.details {
  border-radius: 24px;
  height: 48px;
  left: 7px;
  pointer-events: none;
  position: absolute;
  right: 7px;
  top: 3px;
  width: 50px;

  span {
    color: #fff;
    cursor: pointer;
    display: grid;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 1;
    place-content: center;
    width: 50px;
  }
}

.marker {
  height: 64px;
  left: -26px;
  pointer-events: none;
  position: absolute;
  top: -43px;
  width: 64px;
}

.pin {
  height: 100%;
  width: 100%;

  circle {
    fill: #fff;
  }

  path {
    pointer-events: all;
    stroke: #fff;
    stroke-width: 0.5;
  }
}

.popoverTextContent {
  background-color: $pt-app-background-color;
  color: $pt-text-color;
  padding: 10px;
}