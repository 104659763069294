@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-forgot-password {
  .#{$bp-ns}-dialog-body {
    margin-bottom: 0;
  }

  .#{$bp-ns}-callout {
    margin-bottom: 1rem;
  }

  .#{$bp-ns}-dialog-footer-actions {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .#{$bp-ns}-dialog-footer-actions a:hover {
    text-decoration: none;
  }
}
