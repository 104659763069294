@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";
@import "~@blueprintjs/core/src/components/button/_common";

.container {
  display: inline-block;

  :global {

    .#{$bp-ns}-button:hover {
      background-color: $button-background-color-hover;
    }

    .#{$bp-ns}-button-text {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .#{$bp-ns}-popover {
      border-radius: 0 0 $pt-border-radius $pt-border-radius;
    }

    .#{$bp-ns}-popover-content {
      padding: 5px;
    }

    .#{$bp-ns}-menu-item .#{$bp-ns}-icon {
      margin-right: 10px;
    }
  }
}

.popoverContent {
  :global {
    .#{$bp-ns}-menu {
      .#{$bp-ns}-menu-item {
        padding-left: 7px;
      }

      min-width: unset;
    }
  }
}