@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-log {
  table.#{$bp-ns}-html-table tbody tr:first-child td {
    box-shadow: none;
  }
}

.vg-log__diff {
  color: $gray3;
  margin: 0;
}

.vg-log__add {
  color: $green3;
}

.vg-log__rm {
  color: $red3;
}
