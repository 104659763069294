@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-new-glimpse {
  padding-bottom: 0;
  width: 600px;

  form {
    padding: 16px 20px 0;
  }

  .#{$bp-ns}-callout {
    margin-bottom: 15px;

    ul {
      margin: 0;
      padding-left: 20px;
    }
  }

  .vg-page__footer {
    padding: 0;
  }

  .vg-page__footer p {
    margin: 0;
  }
}

.vg-new-glimpse__form-group {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: minmax(0, 1fr) 100px;

  .#{$bp-ns}-input-group:first-child {
    margin-bottom: 15px;
  }

  .#{$bp-ns}-button {
    display: grid;
    place-content: center;
    text-align: center;
  }
}

.vg-glimpse-footer {
  background-color: $white;
  border-radius: 0 0 6px 6px;
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  display: grid;
  margin: 20px 0 0;
  padding: 15px 0;
  place-items: center;
  pointer-events: none;
  user-select: none;

  p {
    margin-bottom: 2px;
    text-align: center;
  }
}

.vg-glimpse-footer__scl {
  height: 40px;
  margin-bottom: 10px;
  margin-right: 1rem;
}

.vg-glimpse-footer__zyte {
  height: 60px;
}
