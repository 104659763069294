@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-streams__control-bar__container {
  //

  .#{$bp-ns}-navbar {
    align-items: center;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
}

.vg-streams__control-bar__left-group,
.vg-streams__control-bar__right-group {
  display: flex;
  flex-basis: 150px;
  flex-shrink: 1;
}

.vg-streams__control-bar__right-group {
  justify-content: flex-end;
}
