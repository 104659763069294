@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.icon {
  color: $gray3;
  cursor: pointer;
  display: grid;
  height: 30px;
  place-items: center;
  width: 30px;
}

.input input {
  cursor: pointer;
}

span.inputWrapper {
  display: block;
}

.readOnly {
  cursor: default;
}
