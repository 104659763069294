@media (min-width: 1200px) {
  .vg-vault__wrapper {
    display: flex;
  }

  .vg-vault__wrapper > .vg-vault__content {
    flex: 1;
  }

  .vg-vault__stepper {
    padding: 0 20px 0 0;
    width: 20%;
  }
}
