@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.statusPageLink {
  bottom: 1em;
  position: absolute;

  a {
    color: $pt-dark-text-color-muted;
    font-size: 1.25em;
  }
}
