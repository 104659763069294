@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

table.#{$bp-ns}-html-table .vg-anpr__status {
  max-width: 8px;
  padding: 0 4px;
}

table.#{$bp-ns}-html-table .vg-anpr__image-wrapper {
  padding: 0;
}

.vg-anpr__load-more {
  margin: 1rem auto 0;
  width: 25%;
}

.vg-anpr__extra-info {
  font-size: 12px;
  opacity: 0.8;
}
