@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.sessionDialog {
  :global {
    .#{$bp-ns}-callout {
      margin-bottom: 1rem;
    }
  }
}
