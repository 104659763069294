@import "~@blueprintjs/colors/src/_colors";

.detailTable {
  margin: 0;
  width: 100%;

  thead th {
    background-color: $light-gray2;
  }

  tbody th {
    font-weight: bold;
    padding-left: 21px;
  }

  tbody tr:nth-child(even) th {
    background: transparent;
  }

  tbody tr:nth-child(odd) th {
    background: rgba($gray3, 0.15);
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
