@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-anpr-alert__image {
  background: #000;
  position: relative;
  height: 100%;
}

.vg-anpr-alert__image__full {
  display: block;
  height: auto;
  width: 100%;
}

.vg-anpr-alert__image__cropped {
  border: 1px solid #000;
  border-radius: $pt-border-radius;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  bottom: 10px;
  max-height: 90px;
  min-height: 70px;
  position: absolute;
  right: 10px;
}
