@import "GlimpseList/GlimpseList";
@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-streams__sidebar__container {
  background-color: $pt-app-background-color;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 50px);
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
    height: 16px;
    overflow: visible;
    width: 16px;
  }

  .#{$bp-ns}-elevation-0 {
    box-shadow: none;
  }

  .#{$bp-ns}-tree {
    padding: 5px 0;
  }

  .#{$bp-ns}-tree-node-label.vg-stream-name {
    width: 75%;
  }

  .vg-error-callout {
    border-bottom: 1px solid rgba(16, 22, 26, 0.075);
    border-radius: 0;
    padding: 10px 12px 9px;

    .#{$bp-ns}-icon {
      display: none;
    }
  }

  .vg-error-callout--debug {
    margin-left: 0;
  }

  .vg-streams--hideSidebar & {
    overflow: hidden;
    width: 0;
  }

  .vg-streams--showSidebar & {
    width: 250px;
  }
}

.vg-streams__sidebar__panel {
  display: flex;
  flex: 0 1 40px;
  flex-direction: column;
  transition: max-height 0.33333s ease;
}

.vg-streams__sidebar__panel--inactive {
  max-height: 40px;
}

.vg-streams__sidebar__header {
  align-items: center;
  background-color: $light-gray3;
  border: 1px solid $pt-divider-black;
  border-width: 1px 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  min-height: 40px;
  padding: 0 6px;
  width: 100%;

  .#{$bp-ns}-icon {
    margin-right: 5px;
  }

  .vg-streams__sidebar__panel--inactive & {
    .vg-streams__sidebar__content {
      display: none;
    }
  }
}

.vg-streams__sidebar__content {
  overflow-y: auto;
  width: 250px;
}

.vg-streams__sidebar__container--1-panels {
  .vg-streams__sidebar__panel--active {
    max-height: calc(100vh - 50px);
  }
}

.vg-streams__sidebar__container--2-panels {
  .vg-streams__sidebar__panel--active {
    max-height: calc(100vh - 90px);
  }
}

.vg-streams__sidebar__container--3-panels {
  .vg-streams__sidebar__panel--active {
    max-height: calc(100vh - 130px);
  }
}

.vg-favourite-star {
  color: $pt-icon-color;
  display: grid;
  height: 30px;
  place-items: center;
  width: 30px;
}

.vg-favourite-siteName {
  padding-left: 0.5em;
}
