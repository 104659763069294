@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

div.card {
  padding: 10px;
  text-align: center;
}

.correct {
  color: $green1;
  font-weight: 600;
  font-size: 36px;
  line-height: $pt-line-height;
}

.heading {
  color: $gray1;
  font-size: 16px;
  font-weight: 600;
  line-height: $pt-line-height;
}

.incorrect {
  color: $red1;
  font-size: 16px;
  font-weight: 500;
  line-height: $pt-line-height * 1.1;
}

.reads {
  font-size: 16px;
  font-weight: 500;
  line-height: $pt-line-height * 1.1;
  margin-top: 0.5em;
}

.subCounts {
  background-color: $pt-app-background-color;
  border-radius: $pt-border-radius;
  padding: 5px;
}

.total {
  color: $blue1;
  font-size: 16px;
  font-weight: 500;
  line-height: $pt-line-height * 1.1;
}

.unsure {
  color: $orange1;
  font-size: 16px;
  font-weight: 500;
  line-height: $pt-line-height * 1.1;
}
