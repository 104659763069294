@import "VideoItemButton/VideoItemButton";
@import "VideoPtz/VideoPtz";
@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.vg-video-item {
  background-color: #000;
  display: grid;
  grid-template-rows: 20px minmax(0, 1fr);
  width: 100%;
  height: 100%;
}

.vg-video-item--dragging {
  display: none;
}

.vg-video-item__header {
  align-items: center;
  cursor: move;
  display: grid;
  flex-wrap: nowrap;
  grid-template-columns: 60px minmax(0, 1fr) 40px;
  grid-template-rows: 20px;
  height: 20px;
  overflow: hidden;
  place-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vg-video-item__title {
  flex: 1;
  font-size: 12px;
  opacity: 0.5;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  transition: opacity 0.5s;
  white-space: nowrap;

  .vg-video-item:hover & {
    opacity: 1;
  }
}

.vg-video-item__body {
  display: none;
  place-items: stretch stretch;
  position: relative;

  .vg-video-item--playing & {
    display: grid;
  }

  .vg-error-callout {
    border-radius: 0;
    max-width: 100%;
    width: 100%;
  }
}

.vg-video-item__status {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .#{$bp-ns}-icon {
    color: $pt-dark-text-color-muted;
  }

  .vg-video-item--playing & {
    display: none;
  }
}

.vg-video-item__message {
  color: $pt-dark-text-color-muted;
  cursor: default;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

.vg-video-item__help {
  color: $pt-dark-text-color-muted;
  font-size: 16px;
  padding: 10px;
  text-align: center;
}

.vg-video-item__left {
  background-color: #000;
  display: flex;
  flex-direction: row;
  width: 60px;
  z-index: 2;
}

.vg-video-item__right {
  display: flex;
  flex-direction: row-reverse;
  width: 40px;
}
