@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";
@import "~@blueprintjs/core/src/common/_mixins";

@import "~@blueprintjs/core/src/_reset";
@import "~@blueprintjs/core/src/_typography";
@import "~@blueprintjs/core/src/accessibility/_focus-states";
@import "~@blueprintjs/core/src/_dark-theme";

@import "~@blueprintjs/core/src/components/alert/alert";
@import "~@blueprintjs/core/src/components/breadcrumbs/breadcrumbs";
@import "~@blueprintjs/core/src/components/button/button";
@import "~@blueprintjs/core/src/components/button/button-group";
@import "~@blueprintjs/core/src/components/callout/callout";
@import "~@blueprintjs/core/src/components/card/card";
@import "~@blueprintjs/core/src/components/collapse/collapse";
// @import "~@blueprintjs/core/src/components/context-menu/context-menu";
@import "~@blueprintjs/core/src/components/divider/divider";
@import "~@blueprintjs/core/src/components/dialog/dialog";
@import "~@blueprintjs/core/src/components/dialog/dialog-body";
@import "~@blueprintjs/core/src/components/dialog/dialog-footer";
// @import "~@blueprintjs/core/src/components/dialog/multistep-dialog";
// @import "~@blueprintjs/core/src/components/drawer/drawer";
// @import "~@blueprintjs/core/src/components/editable-text/editable-text";
@import "~@blueprintjs/core/src/components/forms/index";
@import "~@blueprintjs/core/src/components/html-select/html-select";
@import "~@blueprintjs/core/src/components/html-table/html-table";
// @import "~@blueprintjs/core/src/components/hotkeys/hotkeys";
@import "~@blueprintjs/core/src/components/icon/icon";
@import "~@blueprintjs/core/src/components/menu/menu";
@import "~@blueprintjs/core/src/components/navbar/navbar";
// @import "~@blueprintjs/core/src/components/non-ideal-state/non-ideal-state";
// @import "~@blueprintjs/core/src/components/overflow-list/overflow-list";
@import "~@blueprintjs/core/src/components/overlay/overlay";
// @import "~@blueprintjs/core/src/components/panel-stack/panel-stack";
// @import "~@blueprintjs/core/src/components/panel-stack2/panel-stack2";
@import "~@blueprintjs/core/src/components/popover/popover";
@import "~@blueprintjs/core/src/components/portal/portal";
@import "~@blueprintjs/core/src/components/progress-bar/progress-bar";
@import "~@blueprintjs/core/src/components/skeleton/skeleton";
// @import "~@blueprintjs/core/src/components/slider/slider";
@import "~@blueprintjs/core/src/components/spinner/spinner";
@import "~@blueprintjs/core/src/components/tabs/tabs";
@import "~@blueprintjs/core/src/components/tag/tag";
@import "~@blueprintjs/core/src/components/tag-input/tag-input";
@import "~@blueprintjs/core/src/components/toast/toast";
@import "~@blueprintjs/core/src/components/tooltip/tooltip";
@import "~@blueprintjs/core/src/components/tree/tree";

// @blueprintjs/datetime
@import "~@blueprintjs/datetime/src/blueprint-datetime";

// @blueprintjs/select
// @import "~@blueprintjs/select/src/components/omnibar/omnibar";
// @import "~@blueprintjs/select/src/components/select/multi-select";
@import "~@blueprintjs/select/src/components/select/select";

@font-face {
  font-family: 'blueprint-icons-16';
  src: url('~@blueprintjs/icons/lib/css/blueprint-icons-16.woff') format('woff');
}

@font-face {
  font-family: 'blueprint-icons-20';
  src: url('~@blueprintjs/icons/lib/css/blueprint-icons-20.woff') format('woff');
}

.#{$bp-ns}-toast-container-right {
  left: auto;
  padding: 0 10px 20px 20px;
  // width: 400px;
}

.#{$bp-ns}-toast {
  margin-top: 10px;
  // min-width: 400px;
  // width: 100%;
}

.#{$bp-ns}-input[readonly] {
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.075);
}

textarea.#{$bp-ns}-input {
  font-family: $pt-font-family;
  min-height: 100px;
}

.#{$bp-ns}-menu > li + li {
  margin-top: 1px;
}
