@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.container {
  width: 100%;
  height: 100%;
}

.error {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px;

  .callout {
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1),
                0 1px 1px rgba(16, 22, 26, 0.2),
                0 2px 6px rgba(16, 22, 26, 0.2);
    color: $pt-text-color;
    padding: 20px;
    text-align: center;
  }

  p {
    margin: 10px 0 0;
  }

  :global {
    .#{$bp-ns}-icon {
      color: $red2;
    }
  }
}

.map {
  background-color: #aadaff;
  position: relative;

  :global {
    .#{$bp-ns}-spinner .#{$bp-ns}-spinner-track {
      stroke: rgba(0, 0, 0, 0.1);
    }

    .#{$bp-ns}-spinner .#{$bp-ns}-spinner-head {
      stroke: #666;
    }

    .leaflet-control-zoom > a {
      text-decoration: none;
    }
  }
}

.map .loading {
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  height: 32px;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 32px;
}

:global {
  .#{$bp-ns}-dark {
    :local {
      .map {
        :global {
          .#{$bp-ns}-spinner .#{$bp-ns}-spinner-track {
            stroke: rgba(0, 0, 0, 0.1);
          }

          .#{$bp-ns}-spinner .#{$bp-ns}-spinner-head {
            stroke: #666;
          }
        }
      }
    }
  }
}
