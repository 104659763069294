@import "~@blueprintjs/colors/src/_colors";
@import "~@blueprintjs/core/src/common/_variables";

.selectLevelMenu {
  overflow-x: hidden;
  span:global(.#{$bp-ns}-icon), .menuItemText {
    color: #333;
  }
  div.menuItemText {
    font-size: 13px;
  }
}

.selectLevelButton {
  width: 100px;
  span {
    color: #333;
    font-size: 13px;
  }
}
